<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col lg="12">
        <b-row class="match-height">
          <b-col lg="12" md="6">
            <b-card class="card-transaction" no-body>
              <b-card-header>
                <b-card-title>Répartition des nœuds actifs</b-card-title>
              </b-card-header>

              <b-card-body>
                <l-map style="height: 70vh" :zoom="zoom" :center="center">
                  <l-tile-layer :url="url" :attribution="attribution" />
                  <l-marker
                    v-for="(marker, index) in markers"
                    :key="index"
                    :lat-lng="marker"
                    :icon="defaultIcon"
                  />
                </l-map>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BCardHeader,
} from "bootstrap-vue";
import axios from "axios";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardHeader,
    LMap,
    LTileLayer,
    LMarker,
  },
  data() {
    return {
      zoom: 5,
      center: L.latLng(46.2276, 2.2137),
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      defaultIcon: L.icon({
        // iconUrl: '/public/favicon-32x32.png',
        iconUrl: require('@/assets/images/marker.png'),
        iconSize: [16, 16],
        iconAnchor: [16, 16],
      }),
      markers: [],
    };
  },
  mounted() {
    this.fetchPeers();
  },
  methods: {
    fetchPeers() {
      axios
        .get(`https://apisb.openlogs.fr/api/peers`)
        .then(({ data }) => {
          this.markers = [];
          for (const { latitude, longitude } of data.data) {
            this.markers.push(L.latLng(latitude, longitude));
          }
        })
        .catch((err) => {
          console.error(err);
          alert(`An error has occured - ${err.toString()}`);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>

<style lang="scss" scoped>
.transaction-item .media {
  align-items: center;
}
</style>
